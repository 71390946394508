<template>
  <base-popup
    v-model="dialog"
    title="Добавить существующие образцы"
    :icon="UtilGetIcon('view-DocumentTemplateEdit')"
    @save="$emit('save', selected)"
    :width="width"
    :height="height"
  >
    <list-template
      :value="dataSource"
      :showDelete="false"
      :notShowActions="['add']"
      :search.sync="search"
      :show-add-in-table="false"
      @input="(value) => (this.selected = value)"
      @onRefresh="init"
      :isSelect="true"
      :selectedItems="selectedItems"
    ></list-template>
  </base-popup>
</template>

<script>
import DocumentTemplateService from "../../services/document/DocumentTemplateService";
import ListEditMixin from "../../mixins/ListEditMixin";
import BasePopup from "../../layouts/BasePopup.vue";
import ListTemplate from "./ListTemplate.vue";

export default {
  name: "view-PopupTemplate",
  components: {
    BasePopup,
    ListTemplate,
  },
  mixins: [ListEditMixin],
  props: {
    value: Boolean,
    filter: Array,
    width: String,
    height: String,
    selectedItems: { Array, default: () => [] },
  },
  data: () => {
    return {
      loading: true,
      apiService: DocumentTemplateService,
      source: null,
      dataSource: [],
      selected: [],
      search: "",
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.init();
        }
        this.search = null;
        this.$emit("input", value);
      },
    },
  },

  methods: {
    init() {
      this.baseInit({
        query: {
          archive: false,
          isSample: true,
        },
      });
    },
  },
};
</script>
